import React from "react";
import { Helmet } from "react-helmet";
import styled from "styled-components";

import { Wrapper } from "../../components/layout-components";
import { Headline, Paragraph } from "../../components/type";
import { colorPlates, colors, fonts } from "../../style/theme";

const typography = [
  {
    classes: "h1, p.h1",
    theme: "h1",
    element: Headline,
    size: "h1",
    text: "Classy",
    lineLength: 1,
  },
  {
    classes: "h2, p.h2",
    theme: "h2",
    element: Headline,
    size: "h2",
    text: "An historic event space in Long Beach",
    lineLength: 1,
  },
  {
    classes: "h3, p.h3",
    theme: "h3",
    element: Headline,
    size: "h3",
    text: "Talk about your event.",
    lineLength: 0.6,
  },
  {
    classes: "h4, p.h4",
    theme: "h4",
    element: Headline,
    size: "h4",
    text: "You’re inquiring about having Alex’s Birthday in The Ballroom on April 19th, 2021 at 8am for 100 guests.",
    lineLength: 1.3,
  },
  {
    classes: "h5, p.h5",
    theme: "h5",
    element: Headline,
    size: "h5",
    text: "Talk about your event.",
    lineLength: 1,
  },
  {
    classes: "h6, p.h6, p.title-large",
    theme: "h6",
    element: Headline,
    size: "h6",
    text: "Tour",
    lineLength: 1,
  },
  {
    classes: "title",
    theme: "title",
    element: Paragraph,
    size: "title",
    text: "Head Count",
    lineLength: 1,
  },
  {
    classes: "title-small",
    theme: "titleSmall",
    element: Paragraph,
    size: "title-small",
    text: "Contact Details",
    lineLength: 1,
  },
  {
    classes: "p",
    theme: "paragraph",
    element: Paragraph,
    size: "",
    text: "One of our event professionals will be in touch in the next 2-3 business days.",
    lineLength: 1,
  },
  {
    classes: "p.small",
    theme: "paragraphSmall",
    element: Paragraph,
    size: "small",
    text: "3636 Linden Ave Long Beach, CA 90807",
    lineLength: 1,
  },
];

/**
 * page-template Component
 */
export default function Template() {
  return (
    <>
      <Helmet>
        <meta name="robots" content="noimageindex, nofollow, nosnippet" />
        <meta name="robots" content="noindex" />
      </Helmet>
      <Grid>
        <Headline size="h4">Typography</Headline>
        {typography.map(
          ({ classes, element, size, text, theme, lineLength }, i) => {
            const Element = element;
            return (
              <>
                <Pre>
                  {classes} <br />
                  theme.fonts.{theme}
                  <br />
                  {fonts[theme]}
                </Pre>
                <Element size={size} lineLength={lineLength}>
                  {text}
                </Element>
              </>
            );
          }
        )}
      </Grid>
      <Grid>
        <Headline size="h4">Color Plates</Headline>
        {Object.entries(colorPlates).map((item, i) => (
          // console.log(item[0]);
          <>
            <Pre>theme.colorPlates.{item[0]}</Pre>
            <ColorPlates
              foreground={item[1].foreground}
              background={item[1].background}
              border={item[1].border}
            >
              <Paragraph size="title">
                foreground: {item[1].foreground}
              </Paragraph>
              <Paragraph size="title">
                background: {item[1].background}
              </Paragraph>
              <Paragraph size="title">border: {item[1].border}</Paragraph>
            </ColorPlates>
          </>
        ))}
      </Grid>
      <Grid>
        <Headline size="h4">Base Colors</Headline>
        {Object.entries(colors).map((item, i) => (
          <>
            <Pre>theme.colors.{item[0]}</Pre>
            <Color background={item[1]}>
              <Paragraph size="title">{item[0]}</Paragraph>
              <Paragraph size="title">{item[1]}</Paragraph>
            </Color>
          </>
        ))}
      </Grid>
    </>
  );
}

const Grid = styled(Wrapper)`
  margin-top: 20vmax;
  margin-bottom: 4vw;
`;

const Pre = styled.pre`
  padding-top: 2rem;
  font-size: ${({ theme }) => theme.fonts.h6};
  opacity: 0.66;
`;

const Color = styled.div`
  --size: 15rem;
  --smooth-corners: 15;
  width: var(--size);
  height: var(--size);
  background-color: ${({ background }) => background && background};
  color: ${({ foreground }) => foreground && foreground};
  border-color: ${({ border }) => border && border};
  border: solid 2px;
  padding: 2rem 1.75rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  mask-image: paint(smooth-corners);
  mask-border: paint(smooth-corners);
  border-radius: calc(var(--size) * 0.089);
  overflow: hidden;

  p {
    margin-bottom: 0;
  }
`;

const ColorPlates = styled(Color)`
  --size: 30rem;
  --smooth-corners: 16;
`;
